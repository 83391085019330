import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../../context/auth-context'
import { Container, Row } from 'react-bootstrap';
import GQL from '../../GQL'
import Product from './Product';
import BottomBar from './BottomBar';

const Home = (props) => {
  const { userLogin, clientID, token } = useContext(AuthContext);

  const [listSales, setListSales] = useState([])
  const [refreshCount, setRefreshCount] = useState(0)
  const [detikRefresh] = useState(3000)

  useEffect(() => {
    GQL(`
      query{
         listSales{
            kodesales
            namasales
            status
            statusName
            jamselesai
            gambar
            selisihmenit
        }
      }`, clientID, token).then(resp => {
      if (resp.errors) throw resp.errors
      return resp.data.listSales
    }).then(listSales => {
      setListSales(listSales)
    }).catch(err => {
      if (err instanceof Array && err.length > 0) {
        const [error] = err
        console.log(error);
      } else {
        console.log(err)
      }
    })
    let interval = setInterval(() => {
      console.log("Triggered", refreshCount)
      const _refreshCount = +refreshCount + 1
      setRefreshCount(_refreshCount)
    }, detikRefresh)
    return () => {
      clearInterval(interval)
    };
  }, [refreshCount, detikRefresh, clientID, token])

  const handleReload = () => {

  }


  return (
    <Container fluid={true}>
      <Row>
        <p style={{ marginTop: '20px' }}>Welcome {userLogin}</p>
      </Row>
      <Row>
        {
          listSales.map((el, index) => {
            return (
              <Product item={el} onTriggerRefresh={handleReload} clientID={clientID} token={token} />
            )
          })
        }

      </Row>
      <BottomBar />
    </Container>
  )
}

export default Home;