import React, { useReducer, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './App.css';
import AuthContext from './context/auth-context';
import Login from './Components/Login/Login';
import Home from './Components/Home/Home';
import MyNavbar from './Components/MyNavbar/MyNavbar';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import GQL from './GQL'

const initialValue = {
  token: null,
  clientID: null,
  userLogin: null,
  isCookieLoad: false,
  isLogin: false
}

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        userLogin: action.userLogin,
        isLogin: true
      }
    case "LOGOUT":
      return {
        ...state,
        token: null,
        clientID: null
      }
    case "COOKIE":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        userLogin: action.userLogin,
        isCookieLoad: true
      }
    default: throw new Error("Invalid Action");
  }
}

function App() {

  const [state, dispatch] = useReducer(loginReducer, initialValue)
  const [cookies, setCookie, removeCookie] = useCookies([`bookingApp`]);
  useEffect(() => {
    var render = true;
    if (cookies[`bookingApp-auth`]) {
      const { clientID, token, userLogin } = cookies[`bookingApp-auth`] || { clientID: null, token: null, userLogin: null };
      if (render) {
        //console.log(clientID,token);
        dispatch({ type: "COOKIE", clientID, token, userLogin })
      }
    } else {
      dispatch({ type: "COOKIE", clientID, token })

    }
    return () => {
      render = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies[`bookingApp-auth`]]);

  const login = (username, password) => {
    const userLogin = username
    GQL(`query{
      login(input:{username:"${username}",password:"${password}"}){
        clientID
        token
      }
    }`).then(resp => {
      console.log(resp)
      if (resp.errors) throw resp.errors;
      const token = resp.data.login.token
      const clientID = resp.data.login.clientID
      setCookie(`bookingApp-auth`, { clientID, token, userLogin }, { path: '/' })
      dispatch({ type: "LOGIN", clientID, token, userLogin })
    }).catch(err => {
      if (err[0].message) {
        // setOpenConfirmInfo(true)
        // setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        // setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
      }
    })
  }

  const logout = () => {
    dispatch({ type: "LOGOUT" })
    removeCookie("monkeyPOS-portal-auth")
  }

  const { clientID, token, isCookieLoad, isLogin, userLogin } = state;

  return (
    <Router>
      <React.Fragment>
        <AuthContext.Provider
          value={{
            token,
            clientID,
            login,
            logout,
            userLogin
          }}
        >
          <MyNavbar onLogout={logout} isLogin={(token && clientID)} />
          <Switch>
            {(clientID && token && isCookieLoad) &&
              <React.Fragment>
                <Route path="/" exact component={Home} />
                {isLogin && <Redirect from="/login/" to="/" exact />}
              </React.Fragment>

            }
            {((!clientID || !token) && isCookieLoad) &&
              (
                <React.Fragment>
                  <Route path="/login/" component={Login} />
                  {/* <Route path="/register/" component={Register} /> */}
                  <Redirect to="/login/" />

                </React.Fragment>
              )
            }

          </Switch>
        </AuthContext.Provider>
      </React.Fragment>

    </Router>
  );
}

export default App;
