import React from 'react';
import { Row, Col } from 'react-bootstrap';

const BottomBar = props => {
  return (
    <div style={{ position: 'absolute', bottom: 0, left:0, width: '100%', paddingRight:15, paddingLeft:15 }}>
      <Row>
        <Col xs={12} md={2} style={{backgroundColor:'black', height: '50px'}}>
          <p style={{color:'white', flex: 1, textAlign: 'center', lineHeight:'50px'}}>
            OFF
          </p>
        </Col>
        <Col xs={12} md={2} style={{backgroundColor:'blue', height: '50px'}}>
          <p style={{ color: 'white', flex: 1, textAlign: 'center', lineHeight: '50px' }}>
            KERJA
          </p>
        </Col>
        <Col xs={12} md={2} style={{ backgroundColor: 'green', height: '50px' }}>
          <p style={{ color: 'white', flex: 1, textAlign: 'center', lineHeight: '50px' }}>
            READY
          </p>
        </Col>
        <Col xs={12} md={2} style={{ backgroundColor: 'orange', height: '50px' }}>
          <p style={{ color: 'white', flex: 1, textAlign: 'center', lineHeight: '50px' }}>
            BOOKING
          </p>
        </Col>
        <Col xs={12} md={2} style={{ backgroundColor: 'red', height: '50px' }}>
          <p style={{ color: 'white', flex: 1, textAlign: 'center', lineHeight: '50px' }}>
            END SOON
          </p>
        </Col>
        <Col xs={12} md={2} style={{ backgroundColor: 'white', height: '50px' }}>
          <p style={{ color: 'black', flex: 1, textAlign: 'center', lineHeight: '50px' }}>
            ISTIRAHAT
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default BottomBar;
BottomBar.propTypes = {};
