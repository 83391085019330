import React, { useRef } from 'react'
import { Col } from 'react-bootstrap';
import woman from './woman.png'


const Product = (props) => {
  const fileInputRef = useRef(null);
  const { item, clientID, token } = props;
  let backgroundColor = 'inherit'
  let fontColor = 'white'
  // let style = {}
  if (item.status === 1) {
    backgroundColor = 'green'
    fontColor = 'white'
  } else if (item.status === 5) {
    backgroundColor = 'orange'
    fontColor = 'white'
  } else if (item.status === 30) {
    backgroundColor = 'blue'
    fontColor = 'white'
  } else if (item.status === 8) {
    backgroundColor = 'black'
    fontColor = 'white'
  } else if (item.status === 2) {
    backgroundColor = 'white'
    fontColor = 'black';
  }
  if (item.status === 30 && item.selisihmenit < 15) {
    backgroundColor = 'red'
    fontColor = 'white'
  }
  if (item.namasales.match(/\(OFF\)/g)) {
    backgroundColor = 'black'
    fontColor = 'white'
  }

  const handleClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Check if the selected file is an image
      if (selectedFile.type.startsWith('image/')) {
        // Implement your file upload logic here
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('kodesales', item.kodesales);
        const headers = {
          'Accept': 'application/json',
          // 'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer e462cdabc9b0ba42500c5a5ce49525434e3b1a5ec89f950f49f9f6c9172b366c',
          'X-API-CLIENT-ID': clientID ? clientID : undefined,
          'X-API-TOKEN': token ? token : undefined,
          'Origin': 'localhost:3000',
        }
        // Make a request to your server to handle the file upload
        // Example using fetch:
        fetch(
          `${process.env.REACT_APP_URL || 'https://apizen.ticfood.com'}/upload/`,
          {
            // mode: 'no-cors',
            method: 'POST',
            body: formData,
            headers
          },
        )
          .then(response => response.json())
          .then(data => {
            console.log(data);
            if (data.success) {
              window.alert('Gambar berhasil di upload');
            } else {
              window.alert('Gambar gagal di upload');
            }
          })
          .catch(error => console.error('Error:', error));

        // Note: You need a server-side implementation to handle the file upload
      } else {
        alert('Please select a valid image file.');
      }
    }
  }

  const image = item.gambar ? `${item.gambar}` : woman
  return (
    <Col xs={12} sm={4} md={3} lg={1} style={{ paddingLeft: 1, paddingRight: 1 }}>
      <div style={{ flex: 1 }} onClick={handleClick}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
        <picture >
          <div style={{ display: 'flex', height: '30vh', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: backgroundColor, borderWidth: 1, borderColor: 'white', marginTop: 1 }}>
            <div style={{ display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'center', width: '100%' }}>
              <img class="img-fluid" alt={item.namasales} style={{ height: '26vh', width: '100%', objectFit: 'cover' }} src={image} />
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', paddingLeft: 10, paddingRight: 10, height: '4vh' }}>
              <p style={{ flex: 1, color: fontColor, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', height: '100%', width: '100%' }}>{item.namasales}</p>
              <p style={{ color: fontColor, textAlign: 'right', paddingLeft: 5 }}>{item.selisihmenit}</p>
            </div>
          </div>
        </picture>
      </div>
    </Col>
  )
}

export default Product